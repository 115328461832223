<!-- user -->
<template>
  <el-select
    class="user"
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    remote
    :remote-method="getUsersFuzzy"
    :loading="loading"
    :popper-append-to-body="false"
    :debounce="debounce"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="(item, index) in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    >
      <slot v-if="$scopedSlots.default" :item="item" :index="index" />

      <template v-else>{{ item[labelKey] }}</template>
    </el-option>
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import userStatus from '../../enum/user-status';
  import { getUsers } from '../../api/common';

  export default {
    name: 'user',
    mixins: [select],
    props: {
      // 搜索防抖时长，单位: ms，默认: 500
      debounce: {
        type: Number,
        default: 500,
      },
      // 用户状态，默认: 全部，可选: '已启用'、'已禁用'、'待确认'
      status: {
        type: [Number, Array],
        validator: val => {
          const statusArr = Array.isArray(val) ? val : [val];
          const statusMap = userStatus.map;

          return !statusArr.some(status => !statusMap.hasOwnProperty(status));
        },
        default: () => Object.values(userStatus.enum),
      },
      // 限制个数，默认: 5
      limit: {
        type: Number,
        default: 5,
      },
      // 是否开启数据权限
      enableDataPermission: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    mounted() {
      this.getUsersFuzzy();
    },
    methods: {
      async getUsersFuzzy(keyword) {
        const status = this.status;
        const params = {
          status: Array.isArray(status) ? status.join() : status,
          keyword,
          limit: this.limit,
          enableDataPermission: this.enableDataPermission,
        };

        this.loading = true;

        const res = await getUsers(params);

        this.loading = false;

        if (!res) {
          this.list = [];

          return;
        }

        const { data: users = [] } = res;

        this.list = users.map(item => {
          const { _id, name } = item;

          return {
            ...item,
            [this.labelKey]: name,
            [this.valueKey]: _id,
          };
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .user {
    /deep/ .el-select-dropdown .el-select-dropdown__item {
      height: auto;
    }
  }
</style>
