<!-- anchor-assign -->
<template>
  <el-dialog
    class="anchor-assign"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @closed="handleDialogClosed"
  >
    <el-form
      class="anchor-assign-form"
      ref="form"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="主播名称:">
        <div :style="{ fontSize: '13px' }">{{ assignAnchorName }}</div>
      </el-form-item>

      <el-form-item label="归属类型:" prop="type">
        <el-radio-group v-model="model.type" @change="handleFormTypeChange">
          <el-radio
            v-for="item in anchorBelongsTypeList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="model.type === anchorBelongsTypeEnum.user"
        label="分配对象:"
        prop="userId"
      >
        <user v-model="model.userId" />
      </el-form-item>

      <el-form-item
        v-else-if="model.type === anchorBelongsTypeEnum.group"
        label="分配对象:"
        prop="groupId"
      >
        <group v-model="model.groupId" />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="anchor-assign-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消</el-button
        >

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
        >
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState } from 'vuex';
  import User from '../../../../../../components/user';
  import Group from '../../../../../../components/group';
  import anchorBelongsType from '../../../../../../enum/anchor-belongs-type.js';
  import { updateAnchorBinding } from '../../../../../../api/library/anchor';

  export default {
    name: 'anchor-assign',
    components: {
      User,
      Group,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        model: null,
        rules: {
          type: [{ required: true, message: '请选择', trigger: 'change' }],
          userId: [{ required: true, message: '请选择', trigger: 'change' }],
          groupId: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        saving: false,
      };
    },
    computed: {
      ...mapState('library/anchor', ['assignAnchorObj']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 对话框标题
      title() {
        return '分配主播';
      },
      // 当前分配的主播 id
      assignAnchorId() {
        return this.assignAnchorObj?._id || '';
      },
      // 当前分配的主播名称
      assignAnchorName() {
        return this.assignAnchorObj?.name || '';
      },
      // 主播归属类型枚举值
      anchorBelongsTypeEnum() {
        return anchorBelongsType.enum;
      },
      // 主播归属类型列表
      anchorBelongsTypeList() {
        const { map } = anchorBelongsType;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          type = anchorBelongsType.enum.user,
          userId = '',
          groupId = '',
        } = data || {};

        this.model = {
          type,
          userId,
          groupId,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.assignAnchorId,
        };
        const res = await updateAnchorBinding(params);

        return !!res;
      },
      // 表单归属类型改变
      handleFormTypeChange(val) {
        // 归属类型为公共，重置所选个人 id 和分组 id
        if (val === anchorBelongsType.enum.public) {
          this.model.userId = '';
          this.model.groupId = '';

          return;
        }

        val === anchorBelongsType.enum.user
          ? // 归属类型为个人，重置所选分组 id
            (this.model.groupId = '')
          : // 归属类型为分组，重置所选个人 id
            (this.model.userId = '');
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-assign {
    .anchor-assign-form {
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>
